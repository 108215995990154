<template>
  <div class="app-footer">
    <CookieLaw
      theme="dar-lime"
      :buttonDecline="true"
      buttonText="Accept"
      buttonDeclineText="Decline"
      buttonClass="cookie-btn"
      buttonDeclineClass="cookie-btn-decline"
      storageType="cookies"
      @accept="acceptCookie()"
      @decline="declineCookie()">
      <div slot="message">
        {{ msg }}
        <router-link to="/manual/privacypolicy" target="_blank">
          Privacy Policy.
        </router-link>
      </div>
    </CookieLaw>
    <b-container class="content-container">
      <b-row class="no-gutters" align-v="center">
        <b-col cols="1">
          <img
            class="img-fluid tech-stars"
            src="../../assets/images/techstars_logo_colored.png"
            alt="techstars, logo"
            title="techstars"
          />
        </b-col>
        <b-col cols="7">
          <p class="copyright mb-0">
            &copy; Copyright 2017
            <img class="dash" src="../../assets/images/dash.png" />
            {{ currYear }} HEALTHY HIP HOP INC.
            <br>
            <router-link class="terms-link" to="/manual/terms-of-use">
              TERMS
            </router-link>
            <router-link class="policy-link" to="/manual/privacy-policy">
              PRIVACY POLICY
            </router-link>
            <router-link class="invest-link" to="/invest">
              INVEST
            </router-link>
          </p>
        </b-col>
        <b-col cols="4">
          <!-- SOCIAL MEDIA BUTTONS -->
          <div class="buttons-container">
            <a
              href="https://www.facebook.com/healthyhiphop"
              target="_blank">
              <img
                class="img-fluid facebook"
                src="../../assets/images/facebook-blue.png"
                title="Healthy Hip Hop Facebook"
                alt="facebook" />
            </a>

            <a
              href="https://twitter.com/Healthy_Hip_Hop"
              target="_blank">
              <img
                class="img-fluid twitter"
                src="../../assets/images/twitter-blue.png"
                title="Healthy Hip Hop Twitter"
                alt="twitter" />
            </a>
            
            <a
              href="https://www.instagram.com/healthyhiphop/"
              target="_blank">
              <img
                class="img-fluid instagram"
                src="../../assets/images/instagram-blue.png"
                title="Healthy Hip Hop Instagram"
                alt="instagram" />
            </a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  const currDate = new Date();
  export default {
    data() {
      return {
        msg : `Your satisfaction is important to us! We use cookies so our team
          can improve your overall app experience. To find out more, read our 
          updated `,
        currYear : currDate.getFullYear(),
      }
    },
    components : {
      'CookieLaw' : () => import('vue-cookie-law'),
    },
    methods : {

      /**
       * Accept Cookie
       */
      acceptCookie() {
        window.fbq('consent', 'grant');
      },

      /**
       * Decline Cookie
       */
      declineCookie() {
        window.fbq('consent', 'revoke');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .app-footer {
    position: inherit;
    margin-bottom: 0;
    width: 100%;
    bottom: 0;
    background-color: rgba(255,255,255,0.8);
    border-top: 5px solid $blue;
    color: $blue;
    .copyright, a {
      color: $blue;
      font-family: 'WickedMouse-Bold' !important;
    }
    .buttons-container {
      text-align: right;
    }
    .terms-link {
      padding-right: 0.3rem;
      border-right: 0.1rem solid $blue;
      &:hover {
        color: rgba(0,0,0,.7);
        cursor: pointer;
        text-decoration: none;
      }
    }
    .policy-link {
      padding-left: 0.4rem;
      &:hover {
        color: rgba(0,0,0,.7);
        cursor: pointer;
        text-decoration: none;
      }
    }
    .invest-link {
      padding-left: 0.3rem;
      border-left: 0.1rem solid $blue;
      &:hover {
        color: rgba(0,0,0,.7);
        cursor: pointer;
        text-decoration: none;
      }
    }
    .dash {
      width: 1.75rem;
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    .copyright {
      font-size: 0.35em !important;
    }
    .buttons-container {
      margin: 0.3rem 0;
      .facebook, .twitter, .instagram {
        margin-left: 0.3rem;
        width: 1rem;
      }
    }
    .dash {
      width: 0.5rem !important;
    }
  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .copyright {
      font-size: 0.6em !important;
    }
    .tech-stars {
      width: 80%;
    }
    .buttons-container {
      margin: 0.5rem 0;
      .facebook, .twitter, .instagram {
        margin-left: 0.75rem;
        width: 2rem;
      }
    }
    .dash {
      width: 0.5rem !important;
    }
  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .container {
      padding: 0.5rem 0;
    }
    .copyright {
      font-size: 0.8em !important;
    }
    .buttons-container {
      margin: 0.5rem 0;
      .facebook, .twitter, .instagram {
        margin-left: 0.75rem;
        width: 2.5rem;
      }
    }
    .dash {
      width: 1rem !important;
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .container {
      padding: 0.5rem 0;
    }
    .copyright {
      font-size: 1em !important;
    }
    .buttons-container {
      .facebook, .twitter, .instagram {
        margin-left: 1rem;
        width: 3rem;
      }
    }
  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .container {
      padding: 0.3rem 0;
    }
    .copyright {
      font-size: 1em !important;
    }
    .buttons-container {
      .facebook, .twitter, .instagram {
        margin-left: 1rem;
        width: 3rem;
      }
    }
  }

  @media only screen and (min-width: $special) {
    .container {
      padding: 0.3rem 0;
    }
    .copyright {
      font-size: 0.9em !important;
    }
    .buttons-container {
      .facebook, .twitter, .instagram {
        margin-left: 1rem;
        width: 2.25rem;
      }
    }
  }

  @media only screen and (min-width: $xl2-min) {
    .container {
      padding: 0.4rem 0;
    }
    .tech-stars {
      width: 70%;
    }
    .copyright {
      font-size: 0.75em !important;
    }
    .buttons-container {
      .facebook, .twitter, .instagram {
        margin-left: 1.25rem;
        width: 3rem;
      }
    }
  }

  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .container {
      padding: 0.5rem 0;
    }
    .copyright {
      font-size: 1.05em !important;
    }
    .buttons-container {
      .facebook, .twitter, .instagram {
        margin-left: 1rem;
        width: 3.5rem;
      }
    }
  }
</style>